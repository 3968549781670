import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { SingleFileFormFieldType } from 'common/types/Form';
import { FormFieldProp } from '../types';

export default function SingleFileFormFieldReport(props: FormFieldProp) {
  const { field } = props;
  const files = field.value as SingleFileFormFieldType;
  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text style={[styles.label, styles.bottomMargin10]}>
        {`${field.title}:`}
      </Text>
      <Text style={[styles.value, styles.bottomMargin10]}>{field.label}</Text>
      {files?.length ? (
        <View style={styles.alignItemsCenter}>
          {files.map((file) => {
            return (
              <Image
                key={file.url}
                src={file.url}
                style={[styles.width60percent, styles.bottomMargin10]}
              />
            );
          })}
        </View>
      ) : (
        <Text style={[styles.value, styles.alignTextCenter]}>
          No attachments
        </Text>
      )}
    </Box>
  );
}
