import { useState, ChangeEvent, useEffect } from 'react';
import ImageAttachment from 'styleguide/src/components/ImageAttachment';
import useFileUpload from 'styleguide/src/hooks/useFileUpload';
import { Attachment, MultiFileFormFieldType } from 'common/types/Form';
import {
  EmptyAttachment,
  FileAttachmentContainer,
  FormFieldDescription,
  ImageAttachmentContainer,
  RemoveButton,
  StyledInput,
} from '../JobForm.styles';
import { FormFieldProps } from '../JobFormField';
import BaseFormField from './parts/BaseFormField';
import PlusIcon from 'styleguide/src/components/icons/PlusIcon';

const MultiFileFormField = ({
  field,
  isEditing,
  setTempFilesAdded,
  setTempFilesRemoved,
  setFormField,
  formInstanceId,
  isPreviewOnly,
}: FormFieldProps) => {
  const { value, options } = field || {};
  const files = value as MultiFileFormFieldType;
  const { upload, error } = useFileUpload();
  const [fileUploads, setFileUploads] = useState(files || {});
  const fileUploadsValue =
    fileUploads && Object.keys(fileUploads)?.length
      ? JSON.stringify(fileUploads)
      : '';

  const filesValue =
    files && Object.keys(files)?.length ? JSON.stringify(files) : '';

  const handleFileAdd = async (
    e: ChangeEvent<HTMLInputElement>,
    option: string,
  ) => {
    if (isPreviewOnly) return;
    const file = e.target.files?.[0];
    if (!file) return;
    const fileResponse = await upload(file, `forms/${formInstanceId}`);
    const fileURL = fileResponse?.filePath;
    if (!error && fileURL) {
      setTempFilesAdded((prevState) => {
        return [...(prevState || []), fileURL];
      });
      setFileUploads((prevState) => {
        const items = { ...(prevState || {}) };
        if (!items[option]) {
          items[option] = [];
        }
        items[option].push({ url: fileURL });
        return items;
      });
    }
  };

  const handleFileRemove = (
    option: string,
    index: number,
    file: Attachment,
  ) => {
    setTempFilesAdded((prevState) => {
      return prevState?.filter((url) => url !== file.url);
    });

    setTempFilesRemoved((prevState) => {
      return [...(prevState || []), file.url];
    });
    setFileUploads((prevState) => {
      const files = { ...(prevState || {}) };
      const item = files[option];
      item.splice(index, 1);
      return files;
    });
  };

  useEffect(() => {
    setFormField(field.type, field.title, fileUploadsValue);
  }, [fileUploadsValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFileUploads(files);
  }, [filesValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseFormField field={field}>
      {options?.map((option, i) => (
        <FileAttachmentContainer key={i}>
          <FormFieldDescription variant="bodyStandard">
            {option}
          </FormFieldDescription>
          <ImageAttachmentContainer>
            {!fileUploads?.[option]?.length && !isEditing && (
              <EmptyAttachment>no attachment</EmptyAttachment>
            )}
            {fileUploads?.[option]?.length > 0 && (
              <ImageAttachmentContainer>
                {fileUploads?.[option]?.map((file, index: number) => (
                  <div key={index}>
                    <ImageAttachment file={file} />
                    {isEditing && (
                      <RemoveButton
                        onClick={() => {
                          handleFileRemove(option, index, file);
                        }}
                      >
                        Remove
                      </RemoveButton>
                    )}
                  </div>
                ))}
              </ImageAttachmentContainer>
            )}
            {isEditing && (
              <EmptyAttachment>
                <PlusIcon />
                <StyledInput
                  type="file"
                  id="file"
                  name="file"
                  onChange={(e) => {
                    handleFileAdd(e, option);
                  }}
                />
              </EmptyAttachment>
            )}
          </ImageAttachmentContainer>
        </FileAttachmentContainer>
      ))}
    </BaseFormField>
  );
};

export default MultiFileFormField;
