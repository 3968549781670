import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { FormFieldProp } from '../types';

export default function MultiLineTextInputReport(props: FormFieldProp) {
  const { field } = props;
  const { value } = field;
  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text style={[styles.label, styles.bottomMargin10]}>
        {`${field.title}:`}
      </Text>
      <Text style={styles.value}>
        {value &&
        typeof value === 'object' &&
        'text' in value &&
        typeof value.text === 'string'
          ? value.text
          : 'N/A'}
      </Text>
    </Box>
  );
}
