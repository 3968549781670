import styled from 'styled-components';
import BaseInput, { InputProps } from 'styleguide/src/components/Input';

const Input = styled(BaseInput)<{ $hideBorder?: boolean }>`
  ${({ $hideBorder }) =>
    $hideBorder &&
    `
 > div {
  border-width: 0;
}
 `}
`;

const FormFieldInput = (props: InputProps & { hideBorder?: boolean }) => {
  const { value, hideBorder, ...rest } = props;
  const hasValue = value !== null;
  return (
    <Input
      $hideBorder={hideBorder}
      {...(!hasValue ? { defaultValue: 'N/A' } : {})}
      {...(hasValue ? { value } : {})}
      {...rest}
    />
  );
};

export default FormFieldInput;
