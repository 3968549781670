import React from 'react';
import { FormFieldProp } from '../types';
import { FormFieldType } from 'common/types/Form';
import HourFormFieldReport from './HourFormFieldReport';
import MaterialListFormFieldReport from './MaterialListFormFieldReport';
import SingleFileFormFieldReport from './SingleFileFormFieldReport';
import MultiFileFormFieldReport from './MultiFileFormFieldReport';
import MultiLineTextInputReport from './MultiLineTextInputReport';
import HourCostFormFieldReport from './HourCostFormFieldReport';
import CostSummaryFieldReport from './CostSummary';
import PriceFormFieldReport from './PriceFormField';

export default function FormFieldComponent(props: FormFieldProp) {
  const { field, ...rest } = props;
  switch (field.type) {
    case FormFieldType.HOUR_INPUT:
      return <HourFormFieldReport field={field} {...rest} />;
    case FormFieldType.HOUR_COST:
      return <HourCostFormFieldReport field={field} {...rest} />;
    case FormFieldType.COST_SUMMARY:
      return <CostSummaryFieldReport field={field} {...rest} />;
    case FormFieldType.MATERIAL_LIST_INPUT:
      return <MaterialListFormFieldReport field={field} {...rest} />;
    case FormFieldType.SINGLE_FILE_UPLOAD:
      return <SingleFileFormFieldReport field={field} {...rest} />;
    case FormFieldType.MULTI_FILE_UPLOAD:
      return <MultiFileFormFieldReport field={field} {...rest} />;
    case FormFieldType.MULTI_LINE_TEXT_INPUT:
      return <MultiLineTextInputReport field={field} {...rest} />;
    case FormFieldType.PRICE_INPUT:
      return <PriceFormFieldReport field={field} {...rest} />;
    default:
      return null;
  }
}
