import TextArea, { TextAreaProps } from 'styleguide/src/components/TextArea';
import theme from 'styleguide/src/themes/theme';

const FormFieldTextArea = (props: TextAreaProps) => {
  const { value, ...rest } = props;
  return (
    <TextArea
      disabled
      {...(!value ? { defaultValue: 'N/A' } : {})}
      {...(value ? { value } : {})}
      {...rest}
      style={{
        border: `1px solid ${theme.colors.gray400}`,
      }}
    />
  );
};

export default FormFieldTextArea;
