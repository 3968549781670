import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from 'styleguide/src/components/Typography';
import { MaterialListFieldType } from 'common/types/Form';
import { numberFormat } from 'utils/helpers/formHelpers';
import {
  FormListBody,
  FormListContainer,
  FormListCostCell,
  FormListHead,
  FormListHeadCost,
  FormListHeadName,
  FormListHeadRow,
  FormListNameCell,
  FormListRow,
  AddNewItemCell,
  AddNewItemCellLabel,
} from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import { FormFieldProps } from '../JobFormField';
import PlusIcon from 'styleguide/src/components/icons/PlusIcon';
import FormFieldInput from './parts/FormFieldInput';

type TableRowProps = {
  name: string;
  value: number;
  body?: boolean;
  isEditing?: boolean;
  index?: number;
  isTotal?: boolean;
  updateMaterialItem?: (name: string, value: number, index: number) => void;
};

const FormListTableRow = ({
  name,
  value,
  body,
  isEditing,
  index,
  updateMaterialItem,
  isTotal,
}: TableRowProps) => {
  return (
    <FormListRow>
      <FormListNameCell $editMode={isEditing}>
        {!isEditing && (
          <Typography
            color={isTotal ? 'black' : 'gray700'}
            variant="bodyStandard"
          >
            {name ?? 'N/A'}
          </Typography>
        )}
        {isEditing && (
          <FormFieldInput
            value={name}
            onChange={(e) => updateMaterialItem(e.target.value, value, index)}
            hideBorder
          />
        )}
      </FormListNameCell>
      <FormListCostCell body={body}>
        {!isEditing && (
          <Typography
            color={isTotal ? 'black' : 'gray700'}
            variant="bodyStandard"
          >
            {numberFormat(value, 0)}
          </Typography>
        )}
        {isEditing && (
          <FormFieldInput
            value={value}
            type="number"
            onChange={(e) =>
              updateMaterialItem(name, Number(e.target.value), index)
            }
            min={0}
            hideBorder
          />
        )}
      </FormListCostCell>
    </FormListRow>
  );
};

const MaterialListFormField = ({
  field,
  isEditing,
  setFormField,
}: FormFieldProps) => {
  const { value } = field || {};
  const list = useMemo(() => (value as MaterialListFieldType) || [], [value]);
  const listString = JSON.stringify(list);
  const [materialList, setMaterialList] = useState<MaterialListFieldType>(
    list?.length && Array.isArray(list) ? list : [],
  );

  const sum = useMemo(() => {
    if (!materialList || !materialList.length) return 0;
    return materialList?.reduce<number>((acc, curr) => acc + curr.value, 0);
  }, [materialList]);

  const updateMaterialItem = useCallback(
    (name: string, value: number, index: number) => {
      setMaterialList((prevState) => {
        const _mList = [...prevState];
        const item = _mList[index];
        if (/^(?!\s*$).+/.test(name)) {
          item.name = name;
          item.value = value;
        } else {
          _mList.splice(index, 1);
        }

        return _mList;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMaterialList],
  );

  const AddMaterialRow = () => {
    const label = 'Parts/Materials (if needed)';

    const handleClick = () => {
      setMaterialList((prevState) => {
        return [
          ...(prevState || []),
          {
            name: '',
            value: 0,
          },
        ];
      });
    };

    return (
      <AddNewItemCell onClick={handleClick}>
        <PlusIcon height={13.5} width={13.5} />
        <AddNewItemCellLabel>{label}</AddNewItemCellLabel>
      </AddNewItemCell>
    );
  };

  useEffect(() => {
    setFormField(field.type, field.title, JSON.stringify(materialList));
  }, [materialList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (list?.length && Array.isArray(list)) {
      setMaterialList(list);
    }
  }, [listString]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseFormField field={field}>
      <FormListContainer>
        <FormListHead>
          <FormListHeadRow>
            <FormListHeadName>
              <Typography variant="ctaSmall" color="gray700">
                Name
              </Typography>
            </FormListHeadName>
            <FormListHeadCost>
              <Typography variant="ctaSmall" color="gray700">
                Cost
              </Typography>
            </FormListHeadCost>
          </FormListHeadRow>
        </FormListHead>
        <FormListBody>
          {materialList?.map((item, i) => (
            <FormListTableRow
              key={`${i}`}
              name={item.name}
              value={item.value}
              body
              isEditing={isEditing}
              index={i}
              updateMaterialItem={updateMaterialItem}
            />
          ))}
          {isEditing && (
            <FormListRow>
              <AddMaterialRow />
            </FormListRow>
          )}
          <FormListTableRow
            isTotal
            name="Total Cost of Materials"
            value={sum}
          />
        </FormListBody>
      </FormListContainer>
    </BaseFormField>
  );
};

export default MaterialListFormField;
