import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { MaterialListFieldType } from 'common/types/Form';
import { FormFieldProp } from '../types';
import { numberFormat } from 'utils/helpers/formHelpers';

export default function MaterialListFormFieldReport(props: FormFieldProp) {
  const { field } = props;
  const { value } = field || {};
  const list = useMemo(() => (value as MaterialListFieldType) || [], [value]);

  const sum = useMemo(() => {
    if (!list || !list.length) return 0;
    return list.reduce<number>((acc, curr) => acc + curr.value, 0);
  }, [list]);

  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <View>
        <Text style={[styles.label, styles.bottomMargin10]}>
          {`${field.title}:`}
        </Text>
        <View style={styles.flexRow}>
          <View style={[styles.noShrinkNoGrow, styles.flex70]}>
            <Box
              withoutEdges={['bottom', 'right']}
              style={styles.bottomPadding8}
            >
              <Text style={[styles.label, styles.sizeFont11]}>NAME</Text>
            </Box>
            {list.map((item) => {
              return (
                <Box
                  key={item.name}
                  withoutEdges={['bottom', 'right']}
                  angles={['top']}
                  style={styles.bottomPadding8}
                >
                  <Text style={styles.value}>{item.name}</Text>
                </Box>
              );
            })}
            <Box
              withoutEdges={['right']}
              angles={['top']}
              style={styles.bottomPadding8}
            >
              <Text style={styles.label}>Total</Text>
            </Box>
          </View>
          <View style={[styles.noShrinkNoGrow, styles.flex30]}>
            <Box
              withoutEdges={['bottom']}
              angles={['left']}
              style={styles.bottomPadding8}
            >
              <Text style={[styles.label, styles.sizeFont11]}>COST</Text>
            </Box>
            {list.map((item) => {
              return (
                <Box
                  key={item.name + item.value}
                  withoutEdges={['bottom']}
                  angles={['top']}
                  style={styles.bottomPadding8}
                >
                  <Text style={styles.value}>
                    {numberFormat(item.value, 0)}
                  </Text>
                </Box>
              );
            })}
            <Box angles={['top', 'left']} style={styles.bottomPadding8}>
              <Text style={styles.label}>{numberFormat(sum, 0)}</Text>
            </Box>
          </View>
        </View>
      </View>
    </Box>
  );
}
