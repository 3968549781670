import CostSummaryField from 'components/jobs/detail/components/JobWorkForms/FormFields/CostSummaryField';
import HourCostField from 'components/jobs/detail/components/JobWorkForms/FormFields/HourCost';
import HourFormField from 'components/jobs/detail/components/JobWorkForms/FormFields/HourFormField';
import MaterialListFormField from 'components/jobs/detail/components/JobWorkForms/FormFields/MaterialListFormField';
import MultiFileFormField from 'components/jobs/detail/components/JobWorkForms/FormFields/MultiFileFormField';
import MultiLineTextInput from 'components/jobs/detail/components/JobWorkForms/FormFields/MultiLineTextInput';
import PriceFormField from 'components/jobs/detail/components/JobWorkForms/FormFields/PriceFormField';
import SingleFileFormField from 'components/jobs/detail/components/JobWorkForms/FormFields/SingleFileFormField';
import { FormFieldProps } from 'components/jobs/detail/components/JobWorkForms/JobFormField';
import {
  FormField,
  FormFieldType,
  FormInstance,
  FormLineItem,
  InspectionContext,
} from 'common/types/Form';
import { FC } from 'react';

function safeJsonParser<T>(str: string): T | null {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

export const numberFormat = (value: number, decimal?: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimal ?? 2,
  }).format(value);

export const FORM_COMPONENTS_MAP: Partial<
  Record<FormFieldType, FC<FormFieldProps>>
> = {
  HOUR_INPUT: HourFormField,
  PRICE_INPUT: PriceFormField,
  SINGLE_FILE_UPLOAD: SingleFileFormField,
  MULTI_FILE_UPLOAD: MultiFileFormField,
  MULTI_LINE_TEXT_INPUT: MultiLineTextInput,
  MATERIAL_LIST_INPUT: MaterialListFormField,
  COST_SUMMARY: CostSummaryField,
  HOUR_COST: HourCostField,
};

export const processForm = (form: FormInstance) => {
  const { fields, lineItems } = form || {};

  const parsedFields =
    fields?.map((field) => ({
      ...field,
      value: safeJsonParser<FormField['value']>(field.value as string),
      rawValue: field.value as string,
    })) ?? [];

  let parsedLineItemFields: FormField[] = [];

  const parsedLineItems = lineItems?.map((lineItem) => {
    const parsedItemFields =
      lineItem.fields?.map((field) => ({
        ...field,
        value: safeJsonParser<FormField['value']>(field.value as string),
        rawValue: field.value as string,
      })) ?? [];
    parsedLineItemFields = [...parsedLineItemFields, ...parsedItemFields];
    return {
      ...lineItem,
      fields: parsedItemFields,
    };
  });

  const attachments = [
    ...(parsedFields || []),
    ...(parsedLineItemFields || []),
  ]?.reduce((acc, field) => {
    if (
      field.type === 'SINGLE_FILE_UPLOAD' &&
      Array.isArray(field.value) &&
      field?.value?.length > 0
    ) {
      field.value.forEach((field) => {
        if (typeof field === 'object' && 'url' in field) {
          acc.push(field.url);
        }
      });
    }
    if (
      field.type === 'MULTI_FILE_UPLOAD' &&
      typeof field.value === 'object' &&
      field.value !== null &&
      !Array.isArray(field.value)
    ) {
      Object.entries(field.value)?.map(([, value]) => {
        if (Array.isArray(value)) {
          value.forEach((attachment) => {
            if (typeof attachment === 'object' && 'url' in attachment) {
              acc.push(attachment.url);
            }
          });
        }
      });
    }

    return acc;
  }, []);

  return {
    ...form,
    fields: parsedFields as FormField[],
    attachments,
    lineItems: parsedLineItems as FormLineItem[],
  };
};

export const processInspection = (form: FormInstance) => {
  const getFieldByTitle = (title: string) =>
    form.fields.find((field) => field.title === title);
  const inspectionContext: InspectionContext = {
    inspectionId: getFieldByTitle('Inspection ID')?.value,
    inspectionUrl: getFieldByTitle('Inspection URL')?.value,
  };
  return { ...form, inspectionContext };
};

export const getFieldsFromLineItems = (lineItems: FormLineItem[]) =>
  lineItems?.flatMap(
    (lineItem) =>
      lineItem.fields?.map((field) => ({
        ...field,
        value: safeJsonParser<FormField['value']>(field.value as string),
        rawValue: field.value as string,
      })) ?? [],
  );
