import { Attachment, SingleFileFormFieldType } from 'common/types/Form';
import {
  EmptyAttachment,
  ImageAttachmentContainer,
  StyledInput,
  FileWrapper,
  RemoveButton,
} from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import ImageAttachment from 'styleguide/src/components/ImageAttachment';
import { FormFieldProps } from '../JobFormField';
import { useState, ChangeEvent, useEffect } from 'react';
import useFileUpload from 'styleguide/src/hooks/useFileUpload';
import PlusIcon from 'styleguide/src/components/icons/PlusIcon';

const SingleFileFormField = ({
  field,
  isEditing,
  setTempFilesAdded,
  setTempFilesRemoved,
  setFormField,
  formInstanceId,
  isPreviewOnly,
}: FormFieldProps) => {
  const { value } = field || {};
  const files = value as SingleFileFormFieldType;
  const [fileUploads, setFileUploads] = useState(files || []);
  const { upload, error } = useFileUpload();
  const fileUploadValue = fileUploads?.length
    ? JSON.stringify(fileUploads)
    : '';
  const filesValue = files?.length ? JSON.stringify(files) : '';

  const handleFileAdd = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isPreviewOnly) return;
    const file = e.target.files?.[0];
    const fileResponse = await upload(file, `forms/${formInstanceId}`);
    const fileURL = fileResponse?.filePath;
    if (!error && fileURL) {
      setTempFilesAdded((prevState) => {
        return [...(prevState || []), fileURL];
      });
      setFileUploads((prevState) => {
        return [...(prevState || []), { url: fileURL }];
      });
    }
  };

  const handleFileRemove = (index: number, file: Attachment) => {
    setTempFilesAdded((prevState) => {
      return prevState?.filter((url) => url !== file.url);
    });
    setTempFilesRemoved((prevState) => {
      return [...(prevState || []), file.url];
    });
    setFileUploads((prevState) => {
      const items = [...(prevState || [])];
      items.splice(index, 1);
      return items;
    });
  };

  useEffect(() => {
    setFormField(field.type, field.title, fileUploadValue);
  }, [fileUploadValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFileUploads(files);
  }, [filesValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseFormField field={field}>
      <FileWrapper>
        {Array.isArray(files) && files?.length > 0 && (
          <ImageAttachmentContainer>
            {files?.map((file, i: number) => (
              <div key={`${i}_${file.url}`}>
                <ImageAttachment file={file} />
                {isEditing && (
                  <RemoveButton
                    onClick={() => {
                      handleFileRemove(i, file);
                    }}
                  >
                    Remove
                  </RemoveButton>
                )}
              </div>
            ))}
          </ImageAttachmentContainer>
        )}
        {!files?.length && !isEditing && (
          <EmptyAttachment>no attachment</EmptyAttachment>
        )}
        {isEditing && (
          <EmptyAttachment>
            <PlusIcon />
            <StyledInput
              type="file"
              id="file"
              name="file"
              onChange={handleFileAdd}
            />
          </EmptyAttachment>
        )}
      </FileWrapper>
    </BaseFormField>
  );
};

export default SingleFileFormField;
