import type { SVGAttributes } from 'react';

const StickyNoteIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.6901 12.833H5.16927C7.10227 12.833 8.66927 11.266 8.66927 9.33301V9.33301C8.66927 9.15245 8.73538 8.99634 8.8676 8.86467C8.99927 8.73245 9.15538 8.66634 9.33594 8.66634V8.66634C11.2689 8.66634 12.8359 7.09934 12.8359 5.16634V1.68717C12.8359 1.5344 12.7873 1.4094 12.6901 1.31217C12.5929 1.21495 12.4679 1.16634 12.3151 1.16634H1.6901C1.53733 1.16634 1.41233 1.21495 1.3151 1.31217C1.21788 1.4094 1.16927 1.5344 1.16927 1.68717V12.3122C1.16927 12.465 1.21788 12.59 1.3151 12.6872C1.41233 12.7844 1.53733 12.833 1.6901 12.833ZM1.6901 13.6663C1.3151 13.6663 0.99566 13.5344 0.731771 13.2705C0.467882 13.0066 0.335938 12.6872 0.335938 12.3122V1.68717C0.335938 1.31217 0.467882 0.99273 0.731771 0.728841C0.99566 0.464952 1.3151 0.333008 1.6901 0.333008H12.3151C12.6901 0.333008 13.0095 0.464952 13.2734 0.728841C13.5373 0.99273 13.6693 1.31217 13.6693 1.68717V7.25949C13.6693 8.32035 13.2478 9.33777 12.4977 10.0879L10.0908 12.4948C9.3407 13.2449 8.32328 13.6663 7.26242 13.6663H1.6901ZM3.98177 8.20801C3.87066 8.20801 3.77344 8.16967 3.6901 8.09301C3.60677 8.0169 3.5651 7.91634 3.5651 7.79134C3.5651 7.68023 3.60677 7.58301 3.6901 7.49967C3.77344 7.41634 3.87066 7.37467 3.98177 7.37467H6.58594C6.69705 7.37467 6.79427 7.41634 6.8776 7.49967C6.96094 7.58301 7.0026 7.68023 7.0026 7.79134C7.0026 7.91634 6.96094 8.0169 6.8776 8.09301C6.79427 8.16967 6.69705 8.20801 6.58594 8.20801H3.98177ZM3.98177 4.91634C3.87066 4.91634 3.77344 4.87467 3.6901 4.79134C3.60677 4.70801 3.5651 4.61079 3.5651 4.49967C3.5651 4.37467 3.60677 4.27384 3.6901 4.19717C3.77344 4.12106 3.87066 4.08301 3.98177 4.08301H10.0234C10.1345 4.08301 10.2318 4.12467 10.3151 4.20801C10.3984 4.29134 10.4401 4.38856 10.4401 4.49967C10.4401 4.62467 10.3984 4.72551 10.3151 4.80217C10.2318 4.87829 10.1345 4.91634 10.0234 4.91634H3.98177ZM1.16927 12.833V1.68717V1.68717V12.3122C1.16927 12.465 1.16927 12.59 1.16927 12.6872C1.16927 12.7844 1.16927 12.833 1.16927 12.833Z"
      fill="currentColor"
    />
  </svg>
);

export default StickyNoteIcon;
