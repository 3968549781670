import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { HourFieldType } from 'common/types/Form';
import { FormFieldProp } from '../types';

export default function HourFormFieldReport(props: FormFieldProp) {
  const { field } = props;
  const { value } = field || {};
  const { hours, minutes } = (value || {}) as HourFieldType;

  const text = `${hours ? `${hours} Hours` : ''}${
    hours && minutes ? ', ' : ' '
  }${minutes ? `${minutes} Minutes` : ''}`.trim();

  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text style={styles.label}>
        {`${field.title}: `}
        <Text style={styles.value}>{text || 'N/A'}</Text>
      </Text>
    </Box>
  );
}
