import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { FormFieldProp } from '../types';
import { numberFormat } from 'utils/helpers/formHelpers';

export default function PriceFormFieldReport(props: FormFieldProp) {
  const { field } = props;
  const { rawValue } = field || {};

  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text style={styles.label}>
        {`${field.title}: `}
        <Text style={styles.value}>
          {rawValue ? numberFormat(Number(rawValue)) : 'N/A'}
        </Text>
      </Text>
    </Box>
  );
}
