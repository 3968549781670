import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { MultiFileFormFieldType } from 'common/types/Form';
import { FormFieldProp } from '../types';

export default function MultiFileFormFieldReport(props: FormFieldProp) {
  const { field } = props;
  const files = field.value as MultiFileFormFieldType;
  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text style={[styles.label, styles.bottomMargin10]}>
        {`${field.title}:`}
      </Text>
      {field.options?.map((option) => {
        return (
          <View key={option} style={styles.bottomMargin10}>
            <Text style={[styles.value, styles.bottomMargin10]}>{option}</Text>
            {files?.[option]?.length ? (
              <View style={styles.alignItemsCenter}>
                {files[option].map((file) => {
                  return (
                    <Image
                      key={file.url}
                      src={file.url}
                      style={[styles.width60percent, styles.bottomMargin10]}
                    />
                  );
                })}
              </View>
            ) : (
              <Text style={[styles.value, styles.alignTextCenter]}>
                No attachments
              </Text>
            )}
          </View>
        );
      })}
    </Box>
  );
}
