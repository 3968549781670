import FormFieldInput from './parts/FormFieldInput';
import { FormInputWrapper, PriceFormWrapper } from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import { useState, useEffect, useMemo } from 'react';
import { FormFieldProps } from '../JobFormField';
import {
  MaterialListFieldType,
  FormFieldType,
  HourCostType,
} from 'common/types/Form';
import { numberFormat } from 'utils/helpers/formHelpers';

const CostSummaryField = ({ field, fields }: FormFieldProps) => {
  const [materialField, setMaterialField] = useState<MaterialListFieldType>();
  const [laborField, setLaborField] = useState<number>(0);
  const fieldsString = JSON.stringify(fields);

  useEffect(() => {
    const matField = fields.find(
      (field) => field.type === FormFieldType.MATERIAL_LIST_INPUT,
    );
    const labField = fields.find(
      (field) => field.type === FormFieldType.PRICE_INPUT,
    );
    const hourCostField = fields.find(
      (field) => field.type === FormFieldType.HOUR_COST,
    );

    if (matField && matField.value) {
      setMaterialField(matField.value as MaterialListFieldType);
    }
    if (hourCostField) {
      const value = hourCostField.value as HourCostType;
      if (value) {
        setLaborField(value?.cost);
      }
    } else if (labField && labField.value) {
      setLaborField(Number(labField.value));
    }
  }, [fieldsString]); // eslint-disable-line react-hooks/exhaustive-deps

  const materialSum = useMemo(() => {
    if (!materialField || !materialField.length) return 0;
    return materialField?.reduce<number>((acc, curr) => acc + curr.value, 0);
  }, [materialField]);

  return (
    <BaseFormField field={field}>
      <FormInputWrapper>
        <PriceFormWrapper>
          <FormFieldInput
            disabled
            value={numberFormat(materialSum + laborField, 0)}
          />
        </PriceFormWrapper>
      </FormInputWrapper>
    </BaseFormField>
  );
};

export default CostSummaryField;
