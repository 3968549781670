import { useState } from 'react';
import StickyNoteIcon from '../icons/StickyNoteIcon';
import {
  Content,
  FileAttachment,
  FileNoteWrapper,
  ImageContent,
  ImageItemFooter,
  ImageItemModal,
  ImageItemWrapper,
  ImageNote,
  ImageNoteAuthor,
  ImageWrapper,
} from './ImageAttachment.styles';

type AttachmentNoteAuthor = {
  id: string;
  firstName: string;
  lastName: string;
};

type Attachment = {
  url: string;
  note?: string;
  noteAuthor?: AttachmentNoteAuthor;
};

export interface Prop {
  file: Attachment;
  height?: string;
  width?: string;
  disableModal?: boolean;
}

const ImageAttachment = ({ file, height, width, disableModal }: Prop) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ImageItemWrapper $height={height} $width={width}>
        <FileAttachment
          src={file.url}
          {...(disableModal ? {} : { onClick: () => setOpenModal(true) })}
          height={height}
          width={width}
          $disableClick={disableModal}
        />
        {file?.note && (
          <FileNoteWrapper onClick={() => setOpenModal(true)}>
            <StickyNoteIcon />
          </FileNoteWrapper>
        )}
      </ImageItemWrapper>
      {openModal && (
        <ImageItemModal open={openModal} onClose={() => setOpenModal(false)}>
          <Content>
            <ImageWrapper>
              <ImageContent src={file.url} />
            </ImageWrapper>
            {file?.note && (
              <ImageItemFooter>
                {file?.noteAuthor && (
                  <ImageNoteAuthor variant="ctaSmall">
                    {file?.noteAuthor?.firstName} {file?.noteAuthor?.lastName}
                  </ImageNoteAuthor>
                )}
                <ImageNote variant="bodyLarge">{file?.note}</ImageNote>
              </ImageItemFooter>
            )}
          </Content>
        </ImageItemModal>
      )}
    </>
  );
};

export default ImageAttachment;
