import FormFieldInput from './parts/FormFieldInput';
import {
  PriceFormWrapper,
  PriceIndicatorWrapper,
  PriceInputWrapper,
} from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import { useState, useEffect } from 'react';
import { FormFieldProps } from '../JobFormField';

const PriceFormField = ({ field, isEditing, setFormField }: FormFieldProps) => {
  const { rawValue: price } = field || {};
  const [priceValue, setPriceValue] = useState(price ? Number(price) : 0);

  useEffect(() => {
    setFormField(field.type, field.title, `${priceValue}`);
  }, [priceValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Number.isInteger(price) && Number(price) !== priceValue) {
      setPriceValue(Number(price));
    }
  }, [price]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseFormField field={field}>
      <PriceFormWrapper>
        <PriceInputWrapper>
          <PriceIndicatorWrapper>$</PriceIndicatorWrapper>
          <FormFieldInput
            disabled={!isEditing}
            value={priceValue}
            type="number"
            min={0}
            onChange={(e) => setPriceValue(Number(e.target.value))}
          />
        </PriceInputWrapper>
      </PriceFormWrapper>
    </BaseFormField>
  );
};

export default PriceFormField;
