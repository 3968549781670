import styled from 'styled-components';
import Modal from '../Modal';
import Typography from '../Typography';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ImageItemWrapper = styled.div<{
  $height?: string;
  $width?: string;
}>`
  position: relative;
  width: 150px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;

  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $width }) => $width && `height: ${$width};`}
`;

export const FileNoteWrapper = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.gray50};
  background: ${(props) => props.theme.colors.gray50};
  border-radius: 4px;
  bottom: 6px;
  right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FileAttachment = styled.img<{ $disableClick?: boolean }>`
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${({ $disableClick }) => !$disableClick && `cursor: pointer;`}
`;

export const ImageItemModal = styled(Modal)`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
  background-color: #ffffff;
  padding: 0px;
  border-radius: 0px;
  overflow: hidden;
  margin: 0px;
  background-color: ${(props) => props.theme.colors.black};
  #close-button {
    color: ${(props) => props.theme.colors.white};
    right: 49px;
    top: 49px;
    outline: none;
  }
`;

export const ImageWrapper = styled.div`
  padding: 110px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex-grow: 1;
`;

export const ImageContent = styled.img`
  max-height: 100%;
  border-radius: 6px;
`;

export const ImageItemFooter = styled.div`
  bottom: 0;
  width: 100%;
  border-top: ${(props) => `1px solid ${props.theme.colors.gray800}`};
  padding: 35px;
`;

export const ImageNoteAuthor = styled(Typography)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray400};
`;

export const ImageNote = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray400};
`;
