import { useEffect, useState } from 'react';
import { HourFieldType } from 'common/types/Form';
import {
  HourMinuteWrapper,
  HourMinuteSeparator,
  HourMinuteInputWrapper,
} from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import FormFieldInput from './parts/FormFieldInput';
import { FormFieldProps } from '../JobFormField';

const HourFormField = ({ field, isEditing, setFormField }: FormFieldProps) => {
  const { value } = field || {};
  const { hours: defaultHours, minutes: defaultMinutes } = (value ||
    {}) as HourFieldType;
  const [hours, setHours] = useState(
    !Number.isInteger(defaultHours) ? 0 : defaultHours,
  );
  const [minutes, setMinutes] = useState(
    !Number.isInteger(defaultMinutes) ? 0 : defaultMinutes,
  );

  useEffect(() => {
    setFormField(
      field.type,
      field.title,
      JSON.stringify({
        hours,
        minutes,
      }),
    );
  }, [hours, minutes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Number.isInteger(defaultHours)) {
      setHours(defaultHours);
    }

    if (Number.isInteger(defaultMinutes)) {
      setMinutes(defaultMinutes);
    }
  }, [defaultHours, defaultMinutes]);

  return (
    <BaseFormField field={field}>
      <HourMinuteWrapper>
        <HourMinuteInputWrapper>
          <FormFieldInput
            disabled={!isEditing}
            type="number"
            value={Number(hours)}
            label="HOURS"
            onChange={(e) => setHours(Number(e.target?.value))}
            min={0}
          />
        </HourMinuteInputWrapper>
        <HourMinuteSeparator>:</HourMinuteSeparator>
        <HourMinuteInputWrapper>
          <FormFieldInput
            disabled={!isEditing}
            type="number"
            value={Number(minutes)}
            label="MINUTES"
            onChange={(e) => {
              const min = Number(e.target?.value);
              setMinutes(min > 59 ? 59 : min);
            }}
            min={0}
            max={59}
          />
        </HourMinuteInputWrapper>
      </HourMinuteWrapper>
    </BaseFormField>
  );
};

export default HourFormField;
