import { WorkOrder } from 'common/types/WorkOrder';

type ILocationFormat = 'CITY_STATE';

export function getLocationString(job: WorkOrder, format?: ILocationFormat) {
  if (format) {
    switch (format) {
      case 'CITY_STATE':
        return `${job.market.cityName}, ${job.market.stateCode}`;
    }
  }
  return `${job.location.building} ${job.location.street}, ${job.market.cityName}, ${job.market.stateCode} ${job.location.postalCode}`;
}
