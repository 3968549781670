import React, { useMemo, Fragment } from 'react';
import {
  getFieldsFromLineItems,
  processForm,
} from '../../../../../../utils/helpers/formHelpers';
import { AppLogo, Box, ReportPage, styles } from './JobFormReportPage.styles';
import { Document, Text, View } from '@react-pdf/renderer';
import { getLocationString } from '../../../../../../utils/helpers/getLocationString';
import FormFieldComponent from './FormFields/FormFieldComponent';
import { WorkOrder } from 'common/types/WorkOrder';
import { FormInstance } from 'common/types/Form';

type Props = {
  workOrder: WorkOrder;
  formInstance: FormInstance;
};

export default function JobFormReport(props: Props) {
  const { workOrder, formInstance } = props;

  const processedFormInstance = useMemo(() => {
    return processForm(formInstance);
  }, [formInstance]);

  const requiredFields = processedFormInstance?.fields?.filter(
    (field) => field.required,
  );
  const optionalFields = processedFormInstance?.fields?.filter(
    (field) => !field.required,
  );
  const lineItems = processedFormInstance?.lineItems || [];

  const allFields = [
    ...(processedFormInstance?.fields || []),
    ...(processedFormInstance?.lineItems
      ? getFieldsFromLineItems(processedFormInstance?.lineItems)
      : []),
  ];

  return (
    <Document>
      <ReportPage>
        <Text style={[styles.label, styles.sizeFont9, styles.bottomMargin10]}>
          WORK-ORDER ID: {workOrder.id}
        </Text>
        <View style={styles.header}>
          <Text>{processedFormInstance?.form.title}</Text>
          <AppLogo />
        </View>
        {Boolean(workOrder.assignee?.firstName) && (
          <Box withoutEdges={['bottom']}>
            <Text style={styles.label}>
              Assignee:{' '}
              <Text style={styles.value}>
                {`${workOrder.assignee.firstName} ${workOrder.assignee.lastName}`}
              </Text>
            </Text>
          </Box>
        )}
        <Box withoutEdges={['bottom']} angles={['top']}>
          <Text style={styles.label}>
            Job: <Text style={styles.value}>{workOrder.title}</Text>
          </Text>
        </Box>
        <Box withoutEdges={['bottom']} angles={['top']}>
          <Text style={styles.label}>
            Job Location:{' '}
            <Text style={styles.value}>{getLocationString(workOrder)}</Text>
          </Text>
        </Box>
        {requiredFields.map((field, idx) => {
          return (
            <FormFieldComponent
              key={`required_${idx}_ ${field.title}`}
              field={field}
              fields={allFields}
            />
          );
        })}
        <Box withoutEdges={['bottom']} angles={['top', 'bottom']}>
          {lineItems.map((lineItem, idx) => (
            <Fragment key={`line_item__des_${idx}_${lineItem.description}`}>
              <Text
                style={[
                  styles.label,
                  styles.bottomMargin10,
                  styles.topMargin10,
                ]}
              >
                Work Description:{' '}
                <Text style={styles.value}>{lineItem.description}</Text>
              </Text>
              {lineItem.fields.map((field, idx) => (
                <FormFieldComponent
                  key={`line_item_field_${idx}_ ${field.title}`}
                  field={field}
                  fields={lineItem.fields}
                />
              ))}
              <Box withoutEdges={['top']} style={styles.verticalPadding2} />
            </Fragment>
          ))}
        </Box>
        {optionalFields.map((field, idx) => {
          return (
            <FormFieldComponent
              key={`optional_${idx}_ ${field.title}`}
              field={field}
              fields={allFields}
            />
          );
        })}
        <Box withoutEdges={['top']} style={styles.verticalPadding2} />
      </ReportPage>
    </Document>
  );
}
