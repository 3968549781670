import styled, { css } from 'styled-components';
import Modal from 'styleguide/src/components/Modal';
import FormsIcon from 'styleguide/src/components/icons/FormsIcon';
import Color from 'color';
import Button from 'styleguide/src/components/Button';
import Typography from 'styleguide/src/components/Typography';
import ContextMenu from 'styleguide/src/components/ContextMenu';
import {
  BodyStandard,
  CTASmall,
  Heading6,
} from 'styleguide/src/components/Typography/Typography.styles';

export const JobWorkFormModalContainer = styled(Modal)`
  position: relative;
  max-width: 1170px;
  max-height: 90vh;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  margin: 5vh auto;
`;

export const JobWorkFormContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0;
    max-height: 90vh;
    overflow-y: auto;
    padding: 98px 60px;
    box-sizing: border-box;
    @media ${theme.breakpoints.medium} {
      padding: 98px 135px;
    }
  `}
`;

export const JobFormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormsIconStyled = styled(FormsIcon)`
  color: ${(props) => props.theme.colors.black};
  margin-left: 16px;
  margin-right: 12px;
`;

export const JobFormFooterStyled = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 24px;
  box-sizing: border-box;
  border-top: 1px solid
    ${(props) => Color(props.theme.colors.black).alpha(0.1).string()};
  z-index: 10;
  background-color: white;
`;

export const JobFormFooterButtons = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const JobFormFooterBadge = styled.div`
  height: 100%;
  color: ${(props) => props.theme.colors.black};
  ${Heading6};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const JobFormFooterBadgeText = styled.div`
  overflow: hidden;
  margin-right: 12px;
`;

export const JobFormFooterBadgeCaret = styled.span`
  margin: 0 10px;
  color: ${(props) => props.theme.colors.black};
`;

export const DetailButton = styled(Button)`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  ${Heading6};
  width: auto;
  display: inline-flex;
  text-decoration: underline;
`;

export const FormFieldContainer = styled.div`
  padding: 0;
`;

export const FormFieldHead = styled(Typography)`
  margin-bottom: 6px;
`;

export const RequiredLabel = styled(Typography)`
  margin-left: 10px;
  color: ${(props) => props.theme.colors.black};
`;

export const FormFieldDescription = styled(Typography)`
  margin-bottom: 24px;
`;

export const FormInputWrapper = styled.div`
  margin-top: 12px;
  max-width: 670px;
`;

export const EmptyAttachment = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px dashed ${(props) => props.theme.colors.gray400};
  border-radius: 8px;
  width: 150px;
  height: 100px;
  ${BodyStandard};
  color: ${({ theme }) => theme.colors.gray700};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const FileAttachmentContainer = styled.div`
  margin-bottom: 30px;
`;

export const ImageAttachmentContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

export const FormListContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  max-width: 670px;
`;

export const FormListHead = styled.thead`
  height: 32px;
  background-color: ${({ theme }) =>
    Color(theme.colors.gray400).alpha(0.5).toString()};
`;

export const FormListHeadRow = styled.tr`
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 4px 4px 0px 0px;
`;

export const FormListHeadName = styled.th`
  text-align: left;
  padding-left: 20px;
`;

export const FormListHeadCost = styled.th`
  text-align: center;
`;

export const FormListBody = styled.tbody``;

export const FormListRow = styled.tr`
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
`;

export const FormListNameCell = styled.td<{ $editMode?: boolean }>`
  width: 80%;
  height: 54px;
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.gray700};

  ${({ $editMode, theme }) =>
    $editMode &&
    `   
    color: ${theme.colors.black};
    padding-left: 0;
  `}
`;

export const FormListCostCell = styled.td<{ body?: boolean }>`
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: ${({ theme, body }) =>
    body ? `1px solid ${theme.colors.gray400}` : 'none'};

  & input {
    text-align: center;
  }
`;

export const Divider = styled.div<{
  marginTop?: number;
  marginBottom?: number;
}>`
  margin-top: ${(props) =>
    Number.isInteger(props.marginTop) ? `${props.marginTop}px` : '35px'};
  margin-bottom: ${(props) =>
    Number.isInteger(props.marginBottom) ? `${props.marginBottom}px` : '45px'};
  height: 1px;
  background: ${(props) => props.theme.colors.black};
  opacity: 0.1;
  width: 100%;
`;

export const FormReportButton = styled(Button)`
  margin-right: 20px;
`;

export const Tag = styled.div`
  background: #eee;
  display: inline-block;
  margin: 8px 12px 0 0;
  padding: 6px;
  border-radius: 5px;
  ${BodyStandard};
`;

export const HourMinuteWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 670px;
`;

export const HourMinuteInputWrapper = styled.div`
  max-width: 270px;
  width: 100%;
`;

export const AddNewItemCell = styled.td`
  height: 54px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const AddNewItemCellLabel = styled(Typography)`
  ${Heading6};
  color: ${({ theme }) => theme.colors.gray700};
  margin: 0 0 0 25px;
`;

export const LineItemContainer = styled.div`
  margin: 45px 0;
`;

export const LineItemContent = styled.div<{ open?: boolean }>`
  overflow: hidden;
  position: relative;
  padding-top: 20px;
`;

export const StyledInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`;

export const FileWrapper = styled.div`
  display: flex;
`;

export const RemoveButton = styled(Button)`
  background: none;
  border: none;
  text-decoration-line: underline;
  ${Heading6};
  color: ${({ theme }) => theme.colors.gray700};
  margin: 0 auto;
  max-width: 152px;
  padding-top: 0px;
`;

export const LineItemRemoveButton = styled.span`
  text-decoration-line: underline;
  ${Heading6};
  color: ${({ theme }) => theme.colors.gray700};
  cursor: pointer;
`;

export const LineItemExpandWrapper = styled.div`
  position: relative;
  z-index: 1;
  & .context_menu_button_wrapper {
    position: absolute;
    top: 70%;
    right: -80px;
    transform: translateY(-50%);
  }
`;

export const NewLineItemButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.machineGreen100};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: auto;
  ${Heading6};
  margin-top: 40px;
  & svg {
    margin-right: -10px;
  }
`;

export const ExpandLineItemButton = styled(NewLineItemButton)`
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  margin-top: 45px;
`;

export const CollapseLineItemButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  padding: 10px;
`;

export const ModalHeader = styled.div`
  display: flex;
`;

export const StyledContextMenu = styled(ContextMenu)`
  min-height: 80px;
  min-width: 360px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  padding: 20px;

  & .context_menu_item {
    padding: 0;
  }

  & .context_label {
    letter-spacing: 1px;
    text-transform: uppercase;
    ${CTASmall};
    line-height: 16px;
    padding: 0;
    max-width: 170px;
    margin-right: 30%;
  }

  & svg {
    height: 10px;
    width: 10px;
    margin-right: 10px;
  }
`;

export const DoneButton = styled.span`
  background: ${({ theme }) => theme.colors.machineGreen600};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 12px;
  ${Heading6};
`;

export const HourMinuteSeparator = styled.div`
  min-width: 5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  max-height: 55px;
`;

export const PriceFormWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 20px;
  max-width: 270px;
  position: relative;

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}}

  & input {
    font-family: ${(props) => props.theme.fontFamily.body};
  }

  & label {
    text-transform: uppercase;
  }
`;

export const PriceIndicatorWrapper = styled.span`
  margin-bottom: 15.5px;
  margin-left: 17.5px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  ${BodyStandard};
`;

export const PriceInputWrapper = styled.div`
  width: 100%;

  & input {
    padding-left: 30px;
  }
`;
