export enum FormStatus {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
}

export enum FormType {
  FORM = 'FORM',
  INSPECTION = 'INSPECTION',
}

export enum FormFieldType {
  TEXT_INPUT = 'TEXT_INPUT',
  HOUR_INPUT = 'HOUR_INPUT',
  TASK_LIST = 'TASK_LIST',
  PRICE_INPUT = 'PRICE_INPUT',
  MULTI_LINE_TEXT_INPUT = 'MULTI_LINE_TEXT_INPUT',
  SINGLE_FILE_UPLOAD = 'SINGLE_FILE_UPLOAD',
  MULTI_FILE_UPLOAD = 'MULTI_FILE_UPLOAD',
  RADIO_SELECT = 'RADIO_SELECT',
  MATERIAL_LIST_INPUT = 'MATERIAL_LIST_INPUT',
  COST_SUMMARY = 'COST_SUMMARY',
  HOUR_COST = 'HOUR_COST',
}

export type HourFieldType = {
  hours: number;
  minutes: number;
};

export type HourCostType = {
  hours: number;
  minutes: number;
  cost: number;
};

export type AttachmentNoteAuthor = {
  id: string;
  firstName: string;
  lastName: string;
};

export type Attachment = {
  url: string;
  note?: string;
  noteAuthor?: AttachmentNoteAuthor;
};

export type MaterialListFieldType = {
  name: string;
  value: number | undefined;
}[];

export type SingleFileFormFieldType = Attachment[];

export type MultiLineTextInput = {
  text: string;
};

export type MultiFileFormFieldType = {
  [key in string]: Attachment[];
};

export type FormField = {
  label: string;
  title: string;
  type: FormFieldType;
  options?: string[];
  required?: boolean;
  rawValue?: string;
  value?:
    | string
    | string[]
    | MultiLineTextInput
    | HourFieldType
    | HourCostType
    | MaterialListFieldType
    | MultiFileFormFieldType
    | SingleFileFormFieldType;
};

export enum FormLocation {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

export type Form = {
  id: number;
  title: string;
  type: FormType;
  description?: string;
  // Ex "IA", short title used to describe from. Should be 3 char max.
  abbreviation: string;
  fields: FormField[];
  createdAt: string;
  updatedAt: string;
  lineItems?: FormLineItem;
  location: FormLocation;
  inspectionClientId?: string;
};

export type FormInstanceField = FormField & {
  value: string;
};

export type FormLineItem = {
  description: string;
  fields: FormField[];
};

export type FormInstanceLineItem = {
  description: string;
  fields: FormInstanceField[];
};

export type FormInstance = {
  createdAt: string;
  updatedAt: string;
  workOrderId: number;
  id: number;
  form: Form;
  fields: FormInstanceField[];
  status: FormStatus;
  formId: number;
  lineItems?: FormInstanceLineItem[];
};

export type InspectionContext = {
  inspectionId: string;
  inspectionUrl: string;
};

export type UpdateFormInstanceRequest = {
  workOrderId: number;
  formInstanceId: number;
  formInstance: Omit<
    FormInstance,
    | 'title'
    | 'formId'
    | 'id'
    | 'abbreviation'
    | 'description'
    | 'form'
    | 'workOrderId'
  >;
};

export type AddFormRequest = {
  form: Form;
};

export type UpdateFormRequest = {
  form: Form;
  formId: number;
};

export type DeleteFormRequest = {
  formId: number;
};
