import React from 'react';
import { Text } from '@react-pdf/renderer';
import { Box, styles } from '../JobFormReportPage.styles';
import { HourCostType } from 'common/types/Form';
import { FormFieldProp } from '../types';
import { numberFormat } from 'utils/helpers/formHelpers';

export default function HourCostFormFieldReport(props: FormFieldProp) {
  const { field } = props;
  const { value } = field || {};
  const { hours, minutes, cost } = (value || {}) as HourCostType;

  const text = `${hours ? `${hours} Hours` : ''}${
    hours && minutes ? ', ' : ' '
  }${minutes ? `${minutes} Minutes` : ''}`.trim();

  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text
        style={[styles.label, styles.bottomMargin10]}
      >{`${field.title}: `}</Text>
      <Text style={[styles.value, styles.bottomMargin10]}>
        Time Estimate: {text || 'N/A'}
      </Text>
      <Text style={styles.value}>
        Total Cost of Labor: {numberFormat(cost, 0) || 'N/A'}
      </Text>
    </Box>
  );
}
