import { FormFieldProp } from '../types';
import {
  HourCostType,
  MaterialListFieldType,
  FormFieldType,
} from 'common/types/Form';
import { useMemo } from 'react';
import { Box, styles } from '../JobFormReportPage.styles';
import { Text } from '@react-pdf/renderer';
import { numberFormat } from 'utils/helpers/formHelpers';

export default function CostSummaryFieldReport({
  field,
  fields,
}: FormFieldProp) {
  const total = useMemo(() => {
    let matTotal: MaterialListFieldType = [];
    let laborTotal = 0;
    const matField = fields?.find(
      (field) => field.type === FormFieldType.MATERIAL_LIST_INPUT,
    );
    const labField = fields?.find(
      (field) => field.type === FormFieldType.PRICE_INPUT,
    );
    const hourCostField = fields?.find(
      (field) => field.type === FormFieldType.HOUR_COST,
    );

    if (matField && matField.value) {
      matTotal = [
        ...matTotal,
        ...((matField.value || []) as MaterialListFieldType),
      ];
    }
    if (hourCostField) {
      const value = hourCostField.value as HourCostType;
      if (value) {
        laborTotal += value.cost;
      }
    } else if (labField && labField.value) {
      laborTotal += Number(labField.value);
    }

    return (
      laborTotal + matTotal?.reduce<number>((acc, curr) => acc + curr.value, 0)
    );
  }, [fields]);

  return (
    <Box withoutEdges={['bottom']} angles={['top']}>
      <Text
        style={[styles.label, styles.bottomMargin10]}
      >{`${field.title}: `}</Text>
      <Text style={[styles.value, styles.bottomMargin10]}>
        Total: {numberFormat(total, 0) || 'N/A'}
      </Text>
    </Box>
  );
}
