import { useState, useEffect } from 'react';
import { FormInputWrapper } from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import FormFieldTextArea from './parts/FormFieldTextArea';
import { FormFieldProps } from '../JobFormField';
import { type MultiLineTextInput } from 'common/types/Form';

const MultiLineTextInput = ({
  field,
  isEditing,
  setFormField,
}: FormFieldProps) => {
  const { value, rawValue } = field || {};
  const [text, setText] = useState<MultiLineTextInput>(
    value as MultiLineTextInput,
  );
  useEffect(() => {
    setFormField(field.type, field.title, JSON.stringify(text));
  }, [text]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (text?.text && JSON.stringify(text?.text) !== rawValue) {
      setText(value as MultiLineTextInput);
    }

    if (!value && !text) {
      setText({ text: 'N/A' });
    }
  }, [rawValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseFormField field={field}>
      <FormInputWrapper>
        <FormFieldTextArea
          disabled={!isEditing}
          value={text?.text}
          onChange={(e) => {
            setText({ text: e.target.value });
          }}
        />
      </FormInputWrapper>
    </BaseFormField>
  );
};

export default MultiLineTextInput;
