import type { ReactNode } from 'react';
import { FormField } from 'common/types/Form';
import {
  FormFieldContainer,
  FormFieldDescription,
  FormFieldHead,
  RequiredLabel,
} from '../../JobForm.styles';

interface Props {
  field: FormField;
  children: ReactNode;
}

function BaseFormField({ field, children }: Props) {
  const { title, label, required } = field;
  return (
    <FormFieldContainer>
      <FormFieldHead variant="heading5">
        {title}
        {!required && (
          <RequiredLabel tagName="span" variant="bodyStandard">
            (Optional)
          </RequiredLabel>
        )}
      </FormFieldHead>
      {label && (
        <FormFieldDescription variant="bodyStandard">
          {label}
        </FormFieldDescription>
      )}
      {children}
    </FormFieldContainer>
  );
}

export default BaseFormField;
