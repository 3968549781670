import type { SVGAttributes } from 'react';

const FormsIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="m19.2902 6.81463-4.6341-4.63414c-.0711-.07242-.1594-.12562-.2567-.15463-.0972-.02901-.2002-.03287-.2994-.01123-.0507-.00928-.1021-.01417-.1537-.01463h-6.70971c-.858.00129-1.68049.3427-2.28719.9494s-.94811 1.42919-.9494 2.28719v13.52681c.00129.858.3427 1.6805.9494 2.2872s1.42919.9481 2.28719.9494h8.98541c.8579-.0013 1.6804-.3427 2.2871-.9494s.9481-1.4292.9494-2.2872v-11.2512c-.0012-.04666-.0061-.09314-.0146-.13903.0227-.09854.0206-.20118-.0063-.29868-.0268-.0975-.0774-.1868-.1474-.25986zm-4.4682-2.7317 2.556 2.55122h-2.556zm3.4146 14.68047c-.0007.5344-.213 1.0467-.5907 1.4247-.3776.3781-.8896.5911-1.4239.5924h-8.98541c-.53477-.0007-1.04744-.2134-1.42558-.5915-.37813-.3781-.59085-.8908-.5915-1.4256v-13.52681c.00065-.53477.21337-1.04744.5915-1.42558.37814-.37813.89081-.59085 1.42558-.5915h6.36581v3.76098c.0013.23202.0944.45411.2589.61772.1646.16361.3872.25545.6192.25545h3.7585z" />
      <path d="m8.20266 11.3171c-.07922.0003-.15772-.015-.23098-.0452-.07326-.0301-.13982-.0745-.19585-.1305l-1-.9756c-.11279-.1137-.17626-.26734-.17671-.42755-.00044-.16021.06219-.31415.17435-.42855.11215-.11441.26483-.18007.42502-.1828.16018-.00273.31501.05769.431.16821l.57561.56341 1.36097-1.31951c.05735-.05646.1253-.101.19994-.13107s.1545-.04507.23499-.04413c.0804.00094.1599.0178.2338.0496.074.03181.1409.07793.1969.13571s.1.12608.1295.20095c.0295.07488.0439.15485.0423.2353-.0016.08046-.019.15981-.0514.23348s-.079.14021-.1372.19577l-1.78785 1.73168c-.11423.1094-.26623.1706-.42439.1708z" />
      <path d="m8.20266 15.1901c-.1595.0006-.31281-.0616-.42683-.1732l-1-.9756c-.11279-.1138-.17626-.2674-.17671-.4276-.00044-.1602.06219-.3141.17435-.4285.11215-.1144.26483-.1801.42502-.1828.16018-.0027.31501.0577.431.1682l.57561.5634 1.36097-1.3195c.05735-.0565.1253-.101.19994-.1311.07464-.03.1545-.045.23499-.0441.0804.0009.1599.0178.2338.0496.074.0318.1409.0779.1969.1357s.1.1261.1295.201c.0295.0748.0439.1548.0423.2353-.0016.0804-.019.1598-.0514.2334-.0324.0737-.079.1402-.1372.1958l-1.78785 1.7342c-.11486.1078-.26686.1672-.42439.1658z" />
      <path d="m8.20266 19.0487c-.07922.0004-.15772-.0149-.23098-.0451-.07326-.0301-.13982-.0745-.19585-.1305l-1-.9756c-.11279-.1138-.17626-.2674-.17671-.4276-.00044-.1602.06219-.3141.17435-.4285.11215-.1144.26483-.1801.42502-.1828.16018-.0027.31501.0577.431.1682l.57561.5634 1.36097-1.3195c.05735-.0565.1253-.101.19994-.1311.07464-.03.1545-.045.23499-.0441.0804.0009.1599.0178.2338.0496.074.0318.1409.0779.1969.1357s.1.1261.1295.201c.0295.0748.0439.1548.0423.2353-.0016.0804-.019.1598-.0514.2334-.0324.0737-.079.1402-.1372.1958l-1.78785 1.7317c-.11423.1094-.26623.1706-.42439.1707z" />
      <path d="m16.3263 10.7805h-4.2903c-.1617 0-.3168-.0642-.4311-.1785-.1144-.1144-.1786-.2695-.1786-.4312s.0642-.31682.1786-.43117c.1143-.11435.2694-.17859.4311-.17859h4.2903c.1617 0 .3168.06424.4311.17859.1144.11435.1786.26947.1786.43117s-.0642.3168-.1786.4312c-.1143.1143-.2694.1785-.4311.1785z" />
      <path d="m16.3263 14.5611h-4.2903c-.1617 0-.3168-.0643-.4311-.1786-.1144-.1144-.1786-.2695-.1786-.4312s.0642-.3168.1786-.4312c.1143-.1143.2694-.1785.4311-.1785h4.2903c.1617 0 .3168.0642.4311.1785.1144.1144.1786.2695.1786.4312s-.0642.3168-.1786.4312c-.1143.1143-.2694.1786-.4311.1786z" />
      <path d="m16.3263 18.3562h-4.2903c-.1617 0-.3168-.0642-.4311-.1786-.1144-.1143-.1786-.2694-.1786-.4311s.0642-.3168.1786-.4312c.1143-.1143.2694-.1786.4311-.1786h4.2903c.1617 0 .3168.0643.4311.1786.1144.1144.1786.2695.1786.4312s-.0642.3168-.1786.4311c-.1143.1144-.2694.1786-.4311.1786z" />
    </g>
  </svg>
);

export default FormsIcon;
