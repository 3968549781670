import { useEffect, useState } from 'react';
import { HourCostType } from 'common/types/Form';
import {
  HourMinuteWrapper,
  HourMinuteSeparator,
  HourMinuteInputWrapper,
  PriceFormWrapper,
  PriceIndicatorWrapper,
  PriceInputWrapper,
} from '../JobForm.styles';
import BaseFormField from './parts/BaseFormField';
import FormFieldInput from './parts/FormFieldInput';
import { FormFieldProps } from '../JobFormField';

const HourCostField = ({ field, isEditing, setFormField }: FormFieldProps) => {
  const { value } = field || {};
  const {
    hours: defaultHours,
    minutes: defaultMinutes,
    cost: defaultCost,
  } = (value || {}) as HourCostType;
  const [hours, setHours] = useState(
    !Number.isInteger(defaultHours) ? 0 : defaultHours,
  );
  const [minutes, setMinutes] = useState(
    !Number.isInteger(defaultMinutes) ? 0 : defaultMinutes,
  );
  const [cost, setCost] = useState(
    !Number.isInteger(defaultCost) ? 0 : defaultCost,
  );

  useEffect(() => {
    setFormField(
      field.type,
      field.title,
      JSON.stringify({
        hours,
        minutes,
        cost,
      }),
    );
  }, [hours, minutes, cost]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Number.isInteger(defaultHours)) {
      setHours(defaultHours);
    }

    if (Number.isInteger(defaultMinutes)) {
      setMinutes(defaultMinutes);
    }

    if (Number.isInteger(defaultCost) && Number(defaultCost) !== cost) {
      setCost(Number(defaultCost));
    }
  }, [defaultHours, defaultMinutes, defaultCost]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseFormField field={field}>
      <HourMinuteWrapper>
        <HourMinuteInputWrapper>
          <FormFieldInput
            disabled={!isEditing}
            type="number"
            value={Number(hours)}
            label="HOURS"
            onChange={(e) => setHours(Number(e.target?.value))}
            min={0}
          />
        </HourMinuteInputWrapper>
        <HourMinuteSeparator>:</HourMinuteSeparator>
        <HourMinuteInputWrapper>
          <FormFieldInput
            disabled={!isEditing}
            type="number"
            value={Number(minutes)}
            label="MINUTES"
            onChange={(e) => {
              const min = Number(e.target?.value);
              setMinutes(min > 59 ? 59 : min);
            }}
            min={0}
            max={59}
          />
        </HourMinuteInputWrapper>
      </HourMinuteWrapper>
      <PriceFormWrapper marginTop="30px">
        <PriceInputWrapper>
          <PriceIndicatorWrapper>$</PriceIndicatorWrapper>
          <FormFieldInput
            disabled={!isEditing}
            value={cost}
            type="number"
            min={0}
            onChange={(e) => setCost(Number(e.target.value))}
            label="Total Cost of Labor"
          />
        </PriceInputWrapper>
      </PriceFormWrapper>
    </BaseFormField>
  );
};

export default HourCostField;
