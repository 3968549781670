import styled from 'styled-components';
import ReactPDF, {
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';
import logo from 'common/assets/images/logo.png';
import { Edge } from './types';

export const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 32,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
  },
  appLogo: { width: 70 },
  box: {
    borderWidth: 1,
    borderColor: '#CACACA',
    borderRadius: 4,
    overflow: 'hidden',
    padding: 8,
    paddingBottom: 18,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noTopRadius: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  noBottomRadius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  noRightRadius: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  noLeftRadius: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noTopBorder: {
    borderTopWidth: 0,
    marginTop: -1,
  },
  noBottomBorder: {
    borderBottomWidth: 0,
    marginBottom: -1,
  },
  noRightBorder: {
    borderRightWidth: 0,
    marginRight: -1,
  },
  noLeftBorder: {
    borderLeftWidth: 0,
    marginLeft: -1,
  },
  label: {
    fontSize: 14,
    color: '#898F89',
  },
  value: {
    fontSize: 12,
    color: '#000',
  },
  sizeFont11: { fontSize: 11 },
  sizeFont9: { fontSize: 9 },
  horizontalPadding20: { paddingHorizontal: 20 },
  bottomMargin4: { marginBottom: 4 },
  bottomMargin10: { marginBottom: 10 },
  topMargin10: { marginTop: 10 },
  bottomPadding8: { paddingBottom: 8 },
  noShrinkNoGrow: {
    flexGrow: 0,
    flexShrink: 0,
  },
  flex70: { flex: 0.7 },
  flex30: { flex: 0.3 },
  width60percent: { width: '60%' },
  verticalPadding2: { paddingVertical: 2 },
  alignTextCenter: { textAlign: 'center' },
  alignItemsCenter: { alignItems: 'center' },
});

export const PDFReportContainer = styled(PDFViewer)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const ReportPage = ({
  children,
  style = {},
  ...props
}: PropsWithChildren<ReactPDF.PageProps>) => {
  return (
    <Page size={'A4'} {...props} style={{ ...styles.page, ...style }}>
      {children}
    </Page>
  );
};

export const AppLogo = () => <Image src={logo.src} style={styles.appLogo} />;

function getAngleStyles(edges?: Edge[]) {
  const style = {};
  if (!edges) {
    return style;
  }
  if (edges.includes('top')) {
    Object.assign(style, styles.noTopRadius);
  }
  if (edges.includes('bottom')) {
    Object.assign(style, styles.noBottomRadius);
  }
  if (edges.includes('right')) {
    Object.assign(style, styles.noRightRadius);
  }
  if (edges.includes('left')) {
    Object.assign(style, styles.noLeftRadius);
  }
  return style;
}

function getEdgeStyles(edges?: Edge[]) {
  const style = {};
  if (!edges) {
    return style;
  }
  if (edges.includes('top')) {
    Object.assign(style, styles.noTopBorder, styles.noTopRadius);
  }
  if (edges.includes('bottom')) {
    Object.assign(style, styles.noBottomBorder, styles.noBottomRadius);
  }
  if (edges.includes('right')) {
    Object.assign(style, styles.noRightBorder, styles.noRightRadius);
  }
  if (edges.includes('left')) {
    Object.assign(style, styles.noLeftBorder, styles.noLeftRadius);
  }
  return style;
}

export const Box = ({
  children,
  style = {},
  angles,
  withoutEdges,
  ...props
}: PropsWithChildren<
  ReactPDF.ViewProps & { angles?: Edge[]; withoutEdges?: Edge[] }
>) => (
  <View
    style={{
      ...styles.box,
      ...style,
      ...getAngleStyles(angles),
      ...getEdgeStyles(withoutEdges),
    }}
    {...props}
  >
    {children}
  </View>
);
